<template>
  <div class="agency-order">
    <div class="text-center">
      <loading :active="isLoading" :can-cancel="false" :is-full-page="false">
      </loading>
    </div>
    <div v-if="!isLoading">
      <div v-if="showWizard">
        <h2>{{ pageTitle }}</h2>
        <div class="progress-step">
          <div class="progress-percent" :style="{ width: (currentStep > 2 ? 2 : currentStep) / totalSteps * 100 + '%' }">
            Step {{ currentStep > 2 ? 2 : currentStep }} of {{ totalSteps }}...
          </div>
        </div>

        <div v-if="currentStep === 1">
          <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="formStep1">
            <form @submit.prevent="handleSubmit(submit)">
              <div class="row step-container">
                <div class="col-md-12">
                  <text-input validate="required" :immediate="false" name="company_name" v-model="user.company_name"
                    :placeholder="'auth.order.company_name' | translate" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required" :immediate="false" name="full_name" v-model="user.full_name"
                    :placeholder="'auth.order.full_name' | translate" />
                </div>
                <div class="col-md-12">
                  <text-input v-show="showEmail" validate="required|email" :immediate="false" name="email"
                    v-model="user.email" :placeholder="'auth.order.email' | translate" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required" :minlength="8" type="password" name="password" v-model="user.password"
                    :placeholder="'auth.order.password' | translate" vid="password" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required|confirmed:password" :minlength="8" type="password"
                    name="password_confirmation" v-model="user.password_confirmation"
                    :placeholder="'auth.order.confirm_password' | translate" />
                </div>
                <div class="col-md-12">
                  <phone-number-input v-model="user.phone_number" name="phone_number"
                    :placeholder="'auth.order.phone_number' | translate" :onlyCountries="[]"
                    :disabledFetchingCountry="false" />
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentStep == 2" class="step-2">
          <template v-if="plan">
            <div v-if="!isImmeidateCharge" class="no-payment-text text-center">
              <h6 class="font-weight-bold">No Payment Today</h6>
              <p>Your card will not be charged until your trial is over. <br />You can cancel at any time prior to the
                charge with no payment.</p>
            </div>
            <div>
              <AddCreditCard :addPaymentMethod="false" :eventBus="eventbus" :plan="plan" :skipTrial="forceNoTrial"
                :defaultCoupon="defaultCoupon" v-on:done="onCardVerified"
                v-on:failed="onCardFailed"></AddCreditCard>
            </div>
          </template>
          <template v-else>
            <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="formStep2">
              <form @submit.prevent="handleSubmit(submit)">
                <div class="row step-container">
                  <div class="col-md-12">
                    <text-input name="Total Businesses" type="number" validate="required" v-model="user.total_business"
                      :label="'auth.order.total_business' | translate" />
                  </div>
                  <div class="col-md-12">
                    <text-input name="Goal Businesses" type="number" validate="required" v-model="user.goal_business"
                      :label="'auth.order.goal_business' | translate" />
                  </div>
                  <div class="col-md-12">
                    <text-input name="Total People" type="number" validate="required" v-model="user.total_people"
                      :label="'auth.order.total_people' | translate" />
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </div>
        <div class="d-block">
          <div class="d-block">
            <Button type="button" :classes="`w-100 btn btn-primary btn-next ${nextButtonClass}`" :loading="processing"
              @click="handleNext()">
              {{ nextButtonLabel }}
            </Button>
          </div>
          <a v-if="showPrevious" href="javascript:;" class="mt-3 d-block prev-link" @click="handlePrev()"><i
              class="fa fa-arrow-left"></i> {{ 'auth.order.prev' | translate }}</a>
        </div>
      </div>
      <div v-else>
        <div v-if="showOrderBump" class="col-md-12">
          <div class="content p-sm-4 text-center">
            <div class="h3 text-blue">
              <span class="font-weight-bold">WAIT!</span>
              <span class="font-weight-bold"> ✋ Before you get started... ✋</span>
            </div>
            <div class="h3 font-weight-light">
              Do you want our team of experts to personally set up your account for you?
            </div>
            <div class="h5 font-weight-light mt-4">
              Instead of you having to spend your time setting up, our team will:
            </div>
            <ul class="text-left mt-4">
              <li>Provide a 30 minute introductory call and Q&A session</li>
              <li>Create your agency account</li>
              <li>Procure and connect your new SMS number</li>
              <li>Set up the detailed settings in your account</li>
              <li>Add the most proven automations so they are ready to start using</li>
              <li>Create your website text widget and show you how to add to your site</li>
              <li>Set up 1 client business account</li>
              <li>Provide a 1 hour training and onboarding session showing you system best practices</li>
            </ul>
            <hr class="ml-sm-5 mr-sm-5">
            <!-- <iframe src="https://player.vimeo.com/video/511322564?title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
            <div class="font-weight-light mt-4" style="font-size: 21px">
              Personalized White Glove Onboarding preventing you from spending hours setting up is <span
                class="price-span" :class="{ 'line-through': isWgoCoupon }">${{ formatPrice(wgoPrice) }}</span> <span
                v-if="isWgoCoupon" class="price-span">${{ formatPrice(this.wgoPriceDiscounted) }}</span> one-time
            </div>
            <div v-if="isWgoCoupon" class="text-center">( Coupon <b>{{ coupon.name }}</b> is applied )</div>
            <div>
              <Button type="button" :classes="`w-100 btn btn-lg btn-primary p-3 mt-4 font-weight-bold`"
                :loading="processing" @click="addOrderBump()">
                Add White Glove Onboarding so I don't have to set it all up
              </Button>
            </div>
            <div v-if="!isImmeidateCharge" class="h5 font-weight-light mt-4">
              You will not be charged until your trial period is over...
            </div>
            <div class="mt-5">
              <a class="text-blue h6" @click="skipOrderBump">
                <u>No thanks, I'd like to set everything up myself</u>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="col-md-12">
          <h3 class="text-center font-weight-bold">
            {{ 'auth.order.step3_title' | translate }}
          </h3>
          <p>
            {{ 'auth.order.step3_p1' | translate }}
          </p>
          <p>
            {{ 'auth.order.step3_p2' | translate }}
          </p>
          <p>
            {{ 'auth.order.step3_p3' | translate }}
          </p>
          <div class="d-block w-100" style="margin-top: -40px">
            <div class="calendly-inline-widget"
              data-url="https://calendly.com/ringbot-io/ringbot-onboarding-setup-call?hide_event_type_details=1&hide_gdpr_banner=1"
              style="min-width:320px;height:700px;"></div>
          </div>
          <div class="skip-wrapper mt-3">
            <a href="javascript:;" @click="onCalendarSkip">{{ 'auth.order.skip' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditCard from './AddCreditCard'
import Loading from 'vue-loading-overlay'

export default {
  components: {
    AddCreditCard, Loading
  },

  data() {
    return {
      isLoading: false,
      processing: false,
      user: {
        company_name: '',
        full_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone_number: '',
        subdomain: '',
        plan: undefined,
        payment_method: undefined,
        coupon: undefined,
        total_business: undefined,
        goal_business: undefined,
        total_people: undefined,
      },
      showEmail: true,
      currentStep: 1,
      plan: null,
      planType: null,
      loadingSubscription: false,
      subdomainTooltip: 'Enter a name for your white label subdomain, this will be where your application resides. For example you could call it localmarketing and your application would be localmarketing.callwidget.co. No capitals or special characters are allowed.',
      forceNoTrial: false,
      totalSteps: 2,
      showWizard: true,
      showOrderBump: false,
      registerToken: undefined,
      eventbus: new Vue(),
      defaultCoupon: null,
      coupon: null,
      wgoPrice: 497,
    }
  },

  mounted() {
    // this.$router.push({ name: "register", query: {plan: this.$route.query.plan, t: this.$route.query.t} })
    let planName = this.$route.query.plan
    this.forceNoTrial = this.$route.query.t === 'n'

    if (this.$route.query.email) {
      this.showEmail = false
      this.user.email = this.$route.query.email;
    }

    if (this.$route.query.name) {
      this.user.full_name = this.$route.query.name;
    }

    if (this.$route.query.coupon) {
      this.defaultCoupon = this.$route.query.coupon;
    }

    if (planName) {
      this.isLoading = true
      this.getPlan(planName)
    }
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    submit() {
    },

    skipOrderBump() {
      this.showOrderBump = false

      this.addCalendarScript()
    },

    addOrderBump() {
      if (this.registerToken) {
        this.processing = true
        const params = {
          token: this.registerToken,
          coupon: this.user.coupon,
        }
        this.$store.dispatch('auth/orderBump', params)
          .then((res) => {
            this.showOrderBump = false
            this.processing = false

            this.addCalendarScript()
          })
          .catch((e) => {
            this.processing = false
          })
      }
    },

    addCalendarScript() {
      let script = document.createElement('script')
      script.src = "https://assets.calendly.com/assets/external/widget.js"
      script.async = "true"

      document.body.append(script)
    },

    handlePrev() {
      this.currentStep--
    },

    async handleNext() {
      let valid = false;
      this.processing = true

      if (this.currentStep == 1) {
        valid = await this.$refs.formStep1.validate()
        let emailValid = await this.checkEmailUnique()
        let domainlValid = true
        // let domainlValid = await this.checkDomainUnique()
        if (!emailValid) {
          valid = false;
          this.showEmail = true
        }
        if (!domainlValid) {
          valid = false;
        }
      } else if (this.currentStep == 2) {
        if (this.plan) {
          valid = true
        } else {
          valid = await this.$refs.formStep2.validate()
        }
      }
      this.processing = false

      if (!valid)
        return;

      if (this.currentStep == 1) {
        let param = {
          company_name: this.user.company_name,
          name: this.user.full_name,
          email: this.user.email,
          phone: this.user.phone_number,
          plan: this.user.plan,
        }
        this.$store.dispatch('auth/postUserInfo', param)
      }

      if (this.currentStep < this.totalSteps) {
        this.currentStep++
      } else {
        this.processing = true
        if (this.plan) {
          this.eventbus.$emit('onValidateCard')
        } else {
          this.register();
        }
      }
    },

    redirectToPromotionPage() {
      window.location.replace('https://www.callwidget.co/go')
    },

    onCardVerified(arg) {
      const { id, coupon } = arg
      this.user.payment_method = id
      this.user.coupon = coupon ? coupon.id : undefined
      this.coupon = coupon;
      this.processing = false
      if (this.user.payment_method) {
        this.register();
      }
    },

    onCardFailed() {
      this.processing = false
    },

    getPlan(planName) {
      this.$store.dispatch('subscription/getPlanByName', { name: planName })
        .then((res) => {
          if (res.data.features.OneTime == 'true') {
            this.redirectToPromotionPage();
          } else {
            this.plan = res.data
            this.user.plan = res.data.id
            if (res.data.metadata.findIndex(item => item.key === 'Promotion' && item.value === 'true') != -1) {
              this.planType = 'promotion'
            }
          }

          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
        })
    },

    register() {
      this.processing = true
      this.user.no_trial = this.forceNoTrial

      this.$store.dispatch('auth/registerAgency', this.user)
        .then((res) => {
          this.registerToken = res.data.data.token
          this.processing = false
          this.showWizard = false
          if (this.plan && !this.defaultCoupon) {
            this.showOrderBump = true
          } else {
            this.skipOrderBump()
          }
        })
        .catch((e) => {
          this.processing = false

          if (e.response.data && e.response.data.errors) {
            if (Object.keys(e.response.data.errors).length) {
              this.currentStep = 1
            }

            this.$nextTick(() => {
              this.$refs.formStep1.setErrors(e.response.data.errors)
            })
          }
        })
    },

    onCalendarSkip() {
      this.$router.push({ name: "order-thankyou" })
    },

    goToDashboard() {
      this.$router.push({ name: "dashboard" })
    },

    nextStep() {
      this.currentStep++
    },

    subscriptionUnlimited() {
      if (this.loadingSubscription) return
      this.loadingSubscription = true

      this.$store.dispatch('subscription/subscriptionUnlimited', this.planType)
        .then((res) => {
          this.loadingSubscription = false
          this.nextStep()
        })
        .catch((err) => {
          this.loadingSubscription = false
        })
    },

    checkEmailUnique() {
      if (this.user.email) {
        const params = {
          email: this.user.email
        }
        this.processing = true
        return this.$store.dispatch('auth/checkEmail', params)
          .then((res) => {
            this.processing = false
            return Promise.resolve(true);
          })
          .catch((e) => {
            if (e.response.data && e.response.data.errors) {
              this.$refs.formStep1.setErrors(e.response.data.errors)
            }
            this.processing = false
            return Promise.resolve(false);
          })
      } else {
        this.processing = false
        return Promise.resolve(false);
      }
    },

    checkDomainUnique() {
      if (this.user.subdomain) {
        const params = {
          subdomain: this.user.subdomain
        }
        this.processing = true
        return this.$store.dispatch('auth/checkDomain', params)
          .then((res) => {
            this.processing = false
            return Promise.resolve(true);
          })
          .catch((e) => {
            if (e.response.data && e.response.data.errors) {
              this.$refs.formStep1.setErrors(e.response.data.errors)
            }
            this.processing = false
            return Promise.resolve(false);
          })
      } else {
        this.processing = false
        return Promise.resolve(false);
      }
    },
  },

  computed: {
    isImmeidateCharge() {
      return this.plan && (!this.plan.trial_period_days || this.forceNoTrial)
    },

    isWgoCoupon() {
      return this.coupon && this.coupon.meta && this.coupon.meta['WGO'] && this.coupon.meta['WGO'] === 'True'
    },

    wgoPriceDiscounted() {
      let res = this.wgoPrice;
      if (this.isWgoCoupon) {
        if (this.coupon.amount_off)
          res = this.wgoPrice - this.coupon.amount_off / 100
        else if (this.coupon.percent_off)
          res = this.wgoPrice * (100 - this.coupon.percent_off) / 100
      }
      return res
    },

    loading() {
      return this.$store.state.auth.loading
    },

    mainDomain() {
      return '.' + this.$store.state.app.sitedata.whitelabel_domain
    },

    unlimitedPrice() {
      if (this.planType == 'promotion')
        return 197;
      return 297;
    },

    pageTitle() {
      let title = `Start Your Account!`;
      if (this.plan && this.plan.trial_period_days && !this.forceNoTrial)
        title = `Start FREE ${this.plan.trial_period_days} Day Trial!`;
      if (this.currentStep === 1) {
        title = 'Start Your Free Trial Now'
      }
      return title
    },

    nextButtonLabel() {
      let label = 'Next'
      if (this.currentStep === this.totalSteps) {
        if (this.plan && this.plan.trial_period_days && !this.forceNoTrial)
          label = 'Start My Free Trial Now'
        else
          label = 'Start My Account Now'
      }
      return label
    },

    nextButtonClass() {
      return this.currentStep === this.totalSteps ? 'btn-success btn-register' : ''
    },

    showPrevious() {
      return this.currentStep != 1
    },
  },
}
</script>

<style lang="scss">
.agency-order {
  width: 840px;
  margin: 0 auto;
  background-color: rgb(242, 242, 242);
  padding: 50px 30px;

  .no-payment-text {
    p {
      font-style: italic;
    }
  }

  .price-span {
    font-weight: bold;
    font-size: 21px;
    display: inline-block;
    padding: 0 5px;
  }

  .line-through {
    text-decoration: line-through;
  }

  .btn-register {
    width: 100%;
    height: 60px;
    text-align: center;

    .atom-spinner {
      display: inline-block;
    }

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .input,
  .multiselect__tags {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    height: 42px;
  }

  .vue-tel-input {
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    border: none;
    height: 46px;

    input {
      height: 100%;
      line-height: 100%;
      border: none;
      margin-top: 0px;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;

    .down-container {
      .link {
        margin-top: 2rem;
      }
    }
  }

  h2 {
    text-align: center;
  }

  .link {
    color: #3578c6;
  }

  .text-blue {
    color: rgb(54, 108, 204);
  }

  .text-brown {
    color: rgb(125, 88, 41) !important;
  }

  .text-grey {
    color: rgb(96, 96, 96);
  }

  .btn-next {
    flex: 1 0 auto;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-register {
    font-size: 25px;
    font-weight: 600;
    padding: 14px;
  }

  .prev-link {
    color: #1a76d2;
  }

  .progress-step {
    background: #f5f5f5;
    border-radius: 20px;
    margin-bottom: 40px;
    color: #FFF;
    position: relative;
    box-shadow: 0px 0px 2px #a4a3a3;
    overflow: hidden;
    height: 36px;

    .progress-percent {
      background: #1a76d2;
      text-align: center;
      font-weight: 600;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      line-height: 36px;
    }
  }

  .vuestic-date-picker {
    display: none;
  }

  .skip-wrapper {
    a {
      color: #1a76d2;
    }
  }

  .ready-dive-in {
    p {
      font-size: 18px;
    }
  }

  .input {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    height: 42px;
    padding: 10px 18px;
  }

  .custom-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 30px;
    margin: 0;
    padding: 0;

    .tooltip-icon {
      font-size: 20px;
      margin-left: 5px;
    }
  }

  .h3 {
    line-height: 1.4;
  }

  .hr-dashed {
    border-top-style: dashed;
  }

  .form-check-input {
    margin-top: -5px;
  }

  .form-check {
    background-color: #fff;
  }

  .btn.btn-success:active,
  .btn.btn-success:focus,
  .btn.btn-success:hover {
    background-color: #24b47e;
    color: #fff;
  }

  .second-container {
    ul {
      font-size: 16px;

      li {
        list-style-type: none;

        &:before {
          content: "\F00C";
          color: rgb(204, 158, 69) !important;
          margin-right: -15px;
          position: relative;
          right: 20px;
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
        }
      }

      li+li {
        margin-top: 24px;
      }
    }
  }

  .secure-img {
    display: block;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .profile-img {
    display: block;
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
  }

  .max-w-350 {
    max-width: 350px;
  }

  .first-container {
    background-color: rgb(242, 242, 242);
    padding-left: 30px;
    padding-right: 30px;
  }

  .second-container {
    background-color: rgb(255, 255, 233);
    padding-left: 45px;
    padding-right: 45px;
  }

  .step-2 {
    .content {
      background-color: #fff;
    }

    .btn {
      white-space: unset;
      line-height: 1.5;
    }

    .btn-warning {
      min-width: 320px;

      .atom-spinner {
        margin: 0 auto;
      }

      &:disabled {
        opacity: 0.5
      }
    }

    @media (max-width: 575px) {
      .content {
        padding: 20px;
      }
    }
  }

  .step-3 {
    .content {
      background-color: #fff;
      text-align: center;
      padding: 40px 15px;
    }

    .h2 {
      font-size: 26px;
    }

    .h4 {
      font-size: 20px;
    }

    @media (max-width: 575px) {
      .content {
        padding: 20px;
      }
    }

    .btn {
      color: #366ccc;
      margin-top: 40px;
      text-decoration: underline;
    }
  }

  .vue-tel-input {
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    border: none;
    height: 46px;

    input {
      height: 100%;
      line-height: 100%;
      border: none;
      margin-top: 0px;
    }
  }
}</style>
